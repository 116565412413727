<template>
  <div class="flex py-5 px-2 justify-between items-center">
    <label class="cursor-pointer select-none" htmlFor="remember-me">
      {{ getFieldLabel(props.column.fieldInfo) }}
    </label>

    <FormCheck.Input
      id="remember-me"
      type="checkbox"
      class="ml-2 border"
      :modelValue="props.filter.value"
      @update:model-value="onUpdateModelValue"
    />
  </div>
</template>

<script setup lang="ts">
  import { FormCheck } from "~/shared/ui/Form";
  import { getFieldLabel } from "~/entities/field";
  import { ColumnFilterProps, ColumnFilterEmits } from "../../lib/interfaces";

  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();

  const onUpdateModelValue = (newData: boolean | null): void => {
    emit("update:filter", {
      filterName: props.column.name,
      data: newData,
    });
  };
</script>

<style scoped></style>
